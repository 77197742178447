import { dataUser } from 'config/constants/auth'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from '../index'
import { fetchStatisticalItems, fetchStatusLoading, getListItems } from './actions'
import { fetchListDataStatic, fetchListingItems, fetchTotalItems } from './fetchData'

export const UseCoreStatistical = (contractMarketplaceAddress: string, account: string, chainId: number) => {
    const dispatch = useDispatch<AppDispatch>();
    const { messageHash } = GetDataStatistical();
    useEffect(() => {
        const getDataListStatistical = async () => {
            try {

                const dataFilter = dataUser.filter(i => i.type === 2);
                dispatch(fetchStatusLoading({ isLoading: true }))
                const resultFetchTotalItems = await fetchTotalItems(contractMarketplaceAddress, chainId);
                const resultListListing = await fetchListingItems(contractMarketplaceAddress, resultFetchTotalItems?.totalItems, account, chainId)
                const resultWrappedInfo = await fetchListDataStatic(resultListListing?.listListing, account, chainId, dataFilter)

                dispatch(fetchStatisticalItems({listWrapped: resultWrappedInfo?.listWrappedItem}))
                dispatch(fetchStatusLoading({ isLoading: false }))

            } catch (e) {
                console.log(e)
            }
        }
        if (contractMarketplaceAddress?.length) {
            getDataListStatistical()
        } else {
            dispatch(getListItems())
        }
    }, [chainId, contractMarketplaceAddress, dispatch, account, messageHash])
}

export const GetDataStatistical = () => {
    const dataStatistical= useSelector<AppState, AppState['statistical']>((state) => state.statistical)
    return dataStatistical
}