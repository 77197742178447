import BigNumber from "bignumber.js";
import moment from "moment";

import { UNITDIFF } from "config";
import ccqWrappedAbi from "config/abi/ccqWrappedAbi.json";
import { ERC20_ABI } from "config/abi/erc20";
import { BASE_RATE_INTERVEST } from "config/constants";
import { ccqSupportItems } from "config/constants/ccqSupport/constants/type";
import { AssetItems, DataUser, IntervestRate, ListDataUser, ListIntervestRate, ListUserAsset, WrappedItems } from "state/asset/type";
import multicall from "utils/multicall";
import { renderTokenPayment } from "utils/renderMarketplaceAddress";
import { getDataEtfType, getMarketplace } from "state/fetchDataPhase2";
import { Items } from "./type";

export const fetchEtfInfo = async (account: string, activeCCQ: ccqSupportItems[], chainId: number, contractMarketplace: string): Promise<WrappedItems[]> => {
    try {
        if (account.length && activeCCQ.length) {
            const calls = activeCCQ.map((item) => ({
                address: item.ccqWrrapped?.address,
                name: "etfInfor",
                params: []
            }));
            const callsNumberTerm = activeCCQ.map((item) => ({
                address: item.ccqWrrapped?.address,
                name: "numberTerm",
                params: []
            }));
            const callOwner = activeCCQ.map((item) => ({
                address: item.ccqWrrapped?.address,
                name: "owner",
                params: []
            }));
            const callPaused = activeCCQ.map((item) => ({
                address: item.ccqWrrapped?.address,
                name: "paused",
                params: []
            }));
            const requestPaused = await multicall(ccqWrappedAbi, callPaused, chainId);
            const requestEtfInfo = await multicall(ccqWrappedAbi, calls, chainId);
            const requestNumberTerm = await multicall(ccqWrappedAbi, callsNumberTerm, chainId);
            const requestOwner = await multicall(ccqWrappedAbi, callOwner, chainId);
            const listItems = requestEtfInfo.map((item, index) => {
                const owner = requestOwner[index][0];
                return {
                    distributor: activeCCQ[index]?.fundMember,
                    nftAddress: activeCCQ[index]?.ccqWrrapped?.address,
                    expireDate: Number(new BigNumber(item?.expireDate.toString()).toString()),
                    intervestTerm: Number(new BigNumber(item?.intervestTerm.toString()).toString()),
                    issueDate: Number(new BigNumber(item?.issueDate.toString()).toString()),
                    ccqName: item?.name,
                    denominations: new BigNumber(item?.price.toString()).dividedBy(1E18).toNumber(),
                    publisher: item?.publisher,
                    totalSupply: Number(new BigNumber(item?.totalSupply.toString()).toString()),
                    numberTerm: Number(new BigNumber(requestNumberTerm[index].toString()).toString()),
                    owner,
                    paused: requestPaused[index][0],
                    isApproveInterVestPayed: owner.toLowerCase() === account.toLowerCase(),
                }
            });
            const getEtfType = await Promise.all(listItems.map(async (item) => {
                const resultEtfType = await getDataEtfType(item.nftAddress, chainId);
                const resultMarketplace = await getMarketplace(item.nftAddress, contractMarketplace, chainId);
                return {
                    ...item,
                    ...resultEtfType,
                    ...resultMarketplace
                }
            }));
            return getEtfType;
        }
        return [];
    } catch (e) {
        console.log('error', e)
        return [];
    }
};


export const fetchGetMyAsset = async (etfInfor: WrappedItems[], account: string, chainId: number): Promise<ListUserAsset> => {
    try {
        const dataUserAsset = [];
        if (etfInfor.length) {
            const calls = etfInfor.map((item) => ({
                address: item.nftAddress,
                name: "getMyAsset",
                params: [item.publisher]
            }));
            const requestGetMyAsset = await multicall(ccqWrappedAbi, calls, chainId);
            for (let index = 0; index < requestGetMyAsset?.length; index++) {
                for (let subIndex = 0; subIndex < requestGetMyAsset[index][0]?.length; subIndex++) {
                    dataUserAsset.push(
                        {
                            nftAddress: etfInfor[index].nftAddress,
                            publisher: etfInfor[index].publisher,
                            nftId: Number(new BigNumber(requestGetMyAsset[index][0][subIndex]?.toString()).toString())
                        }
                    )
                }
            }

            // call read Contract getBalanceOf
            const callGetBalance = dataUserAsset.map((item) => ({
                address: item.nftAddress,
                name: 'balanceOf',
                params: [item?.publisher, Number(item?.nftId)]
            }));
            const resultGetBalance = await multicall(ccqWrappedAbi, callGetBalance, chainId);
            const paymentToken = renderTokenPayment(chainId);
            const parseDataBalance = resultGetBalance?.map((item, index) => {
                return {
                    balanceOf: new BigNumber(item.toString()).dividedBy(new BigNumber(10).pow(paymentToken.decimals)).toString(),
                    nftAddress: dataUserAsset[index]?.nftAddress,
                    nftId: dataUserAsset[index]?.nftId,
                }
            })
            // filter array nftAddress getMyVestList result []
            const filteredArray = etfInfor.filter((item) => {
                return dataUserAsset.every((element) => element.nftAddress !== item.nftAddress);
            });

            // call read Contract getMyVestList
            const callGetMyVestList = dataUserAsset.map((item) => ({
                address: item.nftAddress,
                name: 'getMyVestList',
                params: [item?.publisher, Number(item?.nftId)]
            }));

            // parse data to bignumber
            const resultMyVestList = await multicall(ccqWrappedAbi, callGetMyVestList, chainId);
            const parseDataMyVestList = resultMyVestList.map((item, index) => {
                const mapVestList = item[0].map((l, k) => {
                    return {
                        amount: Number(new BigNumber(l?.amount?.toString()).dividedBy(1E18).toString()),
                        isVested: l?.isVested,
                        nextInterestPaymentDate: Number(new BigNumber(l?.vestDate?.toString()).toString()),
                        intervestPayed: Number(new BigNumber(l?.intervestPayed?.toString()).toString()),
                        index: k + 1
                    }
                });

                return {
                    myVestList: mapVestList,
                    nftAddress: dataUserAsset[index]?.nftAddress,
                }
            });

            const dataVestingMap = filteredArray.map((item) => {
                const myVestList = [];
                for (let vestingMap = 0; vestingMap < item?.numberTerm; vestingMap++) {
                    const formatStartDate = moment(moment(item.issueDate * 1000).format("DD-MM-YYYY HH:mm:ss"), "DD-MM-YYYY HH:mm:ss")
                        .add(UNITDIFF, (item?.intervestTerm * (vestingMap + 1)))
                    const valueOf = moment(formatStartDate).valueOf() / 1000;
                    myVestList.push(
                        {
                            index: vestingMap + 1,
                            nextInterestPaymentDate: valueOf,
                            isVested: false,
                            intervestPayed: false,
                            amount: 0
                        }
                    )
                }
                return {
                    nftAddress: item.nftAddress,
                    myVestList
                }
            });
            const concatArray = [...parseDataMyVestList, ...dataVestingMap];
            // filter array with the same nftAddress
            const filterNftAddress = etfInfor.map((item) => {
                const listVesting = concatArray?.find(vm => vm.nftAddress.toLowerCase() === item.nftAddress.toLowerCase());

                const filterBalance = parseDataBalance?.filter(bl => bl.nftAddress.toLowerCase() === item.nftAddress.toLowerCase());
                const sumBalance = filterBalance?.reduce((curr, prev) => {
                    return curr + Number(prev?.balanceOf)
                }, 0)
                return {
                    ...item,
                    listVesting: listVesting?.myVestList,
                    balanceOf: sumBalance
                }
            });
            return {
                listUserAsset: filterNftAddress,
            }
        }
        return {
            listUserAsset: []
        }
    } catch (error) {
        return {
            listUserAsset: []
        }
    }
};

export const fetchInterVestTermRate = async (activeCCQ: ccqSupportItems[], chainId: number): Promise<ListIntervestRate> => {
    try {
        if (activeCCQ?.length > 0) {
            const calls = activeCCQ.map((item) => ({
                address: item.ccqWrrapped?.address,
                name: "intervestTermRate",
                params: []
            }));

            const requestInterVest = await multicall(ccqWrappedAbi, calls, chainId);
            const parseData = requestInterVest?.map((item, index) => {
                return {
                    profit: new BigNumber(item.toString()).dividedBy(BASE_RATE_INTERVEST).multipliedBy(10).toString(),
                    nftAddress: activeCCQ[index]?.ccqWrrapped?.address
                }
            });
            return {
                listIntervestRate: parseData
            }
        }
        return {
            listIntervestRate: []
        }
    } catch (error) {
        return {
            listIntervestRate: []
        }
    }
};

export const fetchBalanceOf = async (
    listUserAsset: AssetItems[],
    listWrappedItem: WrappedItems[],
    listIntervestRate: IntervestRate[],
    dataUser: DataUser[],
    account: string,
    chainId: number
): Promise<{ listItemHome: Items[] }> => {
    const data = [];
    try {
        const callsOnceTermIntervest = listUserAsset.map((item) => ({
            address: item?.nftAddress,
            name: 'onceTermIntervest',
            params: []
        }));
        const callsNumberTerm = listUserAsset.map((item) => ({
            address: item.nftAddress,
            name: 'numberTerm',
            params: []
        }));
        const getNumberTerm = async () => {
            const resultNumberTerm = await multicall(ccqWrappedAbi, callsNumberTerm, chainId);
            return resultNumberTerm
        }
        const getOneTermInvest = async () => {
            const resultOneTerm = await multicall(ccqWrappedAbi, callsOnceTermIntervest, chainId);
            return resultOneTerm
        }

        const [resultNumberTerm, resultOnceTermIntervest] = await Promise.all(
            [
                getNumberTerm(),
                getOneTermInvest(),
            ]
        )
        const totalTermNumber = Number(new BigNumber(resultNumberTerm.toString()).toString()) || 0;
        for (let index = 0; index < listUserAsset?.length; index++) {
            const lengthVestingMap = listUserAsset[index]?.listVesting?.length - 1;
            const nextInterestPaymentDateLastMonth = listUserAsset[index]?.listVesting[lengthVestingMap].nextInterestPaymentDate;
            const assetItem = listWrappedItem.find((item) => item?.nftAddress?.toLowerCase() === listUserAsset[index]?.nftAddress?.toLowerCase());
            const itemProfit = listIntervestRate?.find((item) => item?.nftAddress?.toLowerCase() === listUserAsset[index]?.nftAddress?.toLowerCase());
            const profit = new BigNumber(itemProfit?.profit).dividedBy(100).toString();
            const totalPrice = Number(assetItem?.denominations) * Number(assetItem?.totalSupply);
            const currentYield = Number(new BigNumber(resultOnceTermIntervest[index].toString()).dividedBy(1E18).toString());

            const currentTime = Date.now()
            const nextVestingMap = listUserAsset[index]?.listVesting?.find((d) => currentTime < d?.nextInterestPaymentDate * 1000)
            const checkDayDiff = nextVestingMap ? moment(moment(nextVestingMap?.nextInterestPaymentDate * 1000)).diff(moment(Number(Date.now())), UNITDIFF) : -1;
            const convertCheckDayDiff = checkDayDiff + 1 > 92 ? 92 : checkDayDiff + 1;

            const paydayNextProfit = nextVestingMap ? (nextVestingMap?.nextInterestPaymentDate) : nextInterestPaymentDateLastMonth || 0;
            const resultUser = dataUser.find((d) => d?.nftAddress.toLowerCase() === listUserAsset[index]?.nftAddress?.toLowerCase())
            data.unshift(
                {
                    totalTermNumber,
                    ...assetItem,
                    ...itemProfit,
                    nftId: listUserAsset[index]?.nftId,
                    profit,
                    paydayNextProfit,
                    dayDiff: convertCheckDayDiff,
                    totalPrice,
                    currentYield,
                    vestingMap: listUserAsset[index]?.listVesting,
                    balanceVndc: resultUser?.balanceVNDC,
                    allowanceVndc: resultUser?.allowanceForNftWrapped
                }
            )
        }
        const resultListByAddress = () => {
            return Array.from(new Set(data?.map(s => s?.nftAddress?.toLowerCase())))
                .map((item) => {
                    return {
                        item,
                        ...data?.find((it) => it?.nftAddress.toLowerCase() === item?.toLowerCase()),
                    }
                }
                )
        }
        const resultListData = resultListByAddress();

        const callsIsIntervestPayed = resultListData.map((item) => ({
            address: item.nftAddress,
            name: 'intervestHistory', // check thanh toan chua
            params: [item.owner, item.paydayNextProfit]
        }));
        const resultIntervestPayed = await multicall(ccqWrappedAbi, callsIsIntervestPayed, chainId);
        return {
            listItemHome: resultListData.map((item, index) =>
            ({
                ...item,
                isPay: resultIntervestPayed[index][0],
                index
            }))
        }
    } catch (error) {
        console.log('error', error)
        return {
            listItemHome: []
        }
    }
};

export const fetchUserBalanceOf = async (contractMarketplaceAddress: string, account: string, chainId: number) => {
    try {
        const paymentToken = renderTokenPayment(chainId)
        const [result] = await multicall(ERC20_ABI, [{
            address: contractMarketplaceAddress,
            name: 'balanceOf',
            params: [account]
        }], chainId);
        return new BigNumber(result).dividedBy(new BigNumber(10).pow(paymentToken.decimals)).toNumber();
    } catch (error) {
        console.log(error)
        return 0;
    }
};


export const fetchListTerm = async (payItem: Items, chainId: number) => {
    try {
        const listTerm = payItem.vestingMap?.map((item, term) => {
            const period = term + 1;
            const checkDayDiff = moment(moment(item?.nextInterestPaymentDate * 1000)).diff((moment(Date.now())), UNITDIFF)
            const convertCheckDayDiff = checkDayDiff + 1 > 92 ? 92 : checkDayDiff + 1

            return {
                dayDiff: convertCheckDayDiff <= 0 ? 0 : `${convertCheckDayDiff}`,
                termNumber: period,
                paydayNextProfit: item?.nextInterestPaymentDate,
                currentYield: payItem.currentYield,
                allowance: payItem.allowance,
                balance: payItem.balance,
                indexTerm: term
            }
        });

        const callsIsIntervestPayed = listTerm.map((item) => ({
            address: payItem.nftAddress,
            name: 'intervestHistory',
            params: [payItem.owner, item?.paydayNextProfit]
        }));
        const resultIntervestPayed = await multicall(ccqWrappedAbi, callsIsIntervestPayed, chainId);
        return listTerm.map((item, index) => ({
            ...item,
            isPay: resultIntervestPayed[index][0]
        }));

    } catch (error) {
        return [];
    }
}

export const fetchDataUser = async (activeCCQ: ccqSupportItems[], tokenAddress: string, account: string, chainId: number): Promise<ListDataUser> => {
    try {
        if (activeCCQ.length) {
            const getBalanceOf = async () => {
                const callsBalance = activeCCQ.map((item) => ({
                    address: tokenAddress,
                    name: "balanceOf",
                    params: [account]
                }));
                const resultBalance = await multicall(ERC20_ABI, callsBalance, chainId);
                return resultBalance
            }
            const getAllowance = async () => {
                const callsBalance = activeCCQ.map((item, index) => ({
                    address: tokenAddress,
                    name: "allowance",
                    params: [account, activeCCQ[index]?.ccqWrrapped.address]
                }));
                const resultAllowance = await multicall(ERC20_ABI, callsBalance, chainId);
                return resultAllowance
            }
            const [resultBalanceOf, resultAllowance] = await Promise.all(
                [
                    getBalanceOf(),
                    getAllowance()
                ]
            );
            const paymentToken = renderTokenPayment(chainId)
            const result = activeCCQ.map((d, i) => {
                const balanceVNDC = new BigNumber(resultBalanceOf[i]?.balance.toString()).dividedBy(new BigNumber(10).pow(paymentToken.decimals)).toString()
                return {
                    nftAddress: d?.ccqWrrapped?.address,
                    balanceVNDC,
                    allowanceForNftWrapped: new BigNumber(resultAllowance[i][0]?.toString()).toString(),
                }
            })
            return {
                listDataUser: result
            }
        }
        return {
            listDataUser: []
        }
    } catch (error) {
        return {
            listDataUser: []
        }
    }
};