import { Flex, Text } from "@pancakeswap/uikit";
import { PAYMENT, formatNumberPayment } from "config";
import React from "react";
import styled from "styled-components";

interface InfoSectionProps {
    windowSize: number;
    totalSupply?: number;
    totalPrice?: number;
    totalDistributor: number;
}

const InfoSection: React.FC<InfoSectionProps> = ({ windowSize, totalPrice, totalSupply, totalDistributor }) => {
    return (
        <WrapInfoSection>
            <CsFlexInfo>
                <CsItem>
                    <CsTitle>Tổng {windowSize > 768 ? "Chứng chỉ Quỹ" : "CCQ"} phát hành</CsTitle>
                    <CsContent>
                        {formatNumberPayment(totalSupply)}
                    </CsContent>
                </CsItem>
                <CsItem>
                    <CsTitle>Tổng đơn vị phân phối</CsTitle>
                    <CsContent>{totalDistributor}</CsContent>
                </CsItem>
                <CsItem>
                    <CsTitle>Tổng tiền CCQ đã phát hành {windowSize > 768 && <p>({PAYMENT})</p>}</CsTitle>
                    <CsContent>
                        {formatNumberPayment(totalPrice)}
                    </CsContent>
                </CsItem>
            </CsFlexInfo>
        </WrapInfoSection>
    );
};
const WrapInfoSection = styled.div`
    margin: 34px 0;
    width: 100%;
    @media screen and (max-width: 600px) {
        margin: 24px 0;
    }
`;
const CsFlexInfo = styled(Flex)`
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
    @media screen and (max-width: 600px) {
        grid-gap: 8px;
    }
`;
const CsItem = styled(Flex)`
    background: ${({ theme }) => theme.colors.white};
    justify-content: center;
    flex-direction: column;
    border-radius: 12px;
    align-items: center;
    padding: 10px;
    height: 165px;
    width: 100%;
    max-width: 33%;
    gap: 14px;
    @media screen and (max-width: 1024px) {
        gap: 6px;
        height: 89px;
        padding: 10px;
    }
`;
const CsTitle = styled(Text)`
    color: ${({ theme }) => theme.colors.textSubtle};
    align-items: center;
    text-align: center;
    letter-spacing: 0.04em;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    @media screen and (max-width: 1024px) {
        color: ${({ theme }) => theme.colors.text};
        font-weight: 600;
        font-size: 12px;
    }
    @media screen and (max-width: 600px) {
        font-size: 10px;
        line-height: 1.5;
    }
`;
const CsContent = styled(Text)`
    color: ${({ theme }) => theme.colors.primary};
    letter-spacing: 0.04em;
    font-weight: 700;
    font-size: 36px;
    line-height: 47px;
    @media screen and (max-width: 1024px) {
        font-size: 16px;
        line-height: 1.5;
    }
    @media screen and (max-width: 600px) {
        font-size: 13px;
        font-weight: 700;
        line-height: 1.5;
    }
`;
export default InfoSection;
