import styled from "styled-components";
import { Text } from '@pancakeswap/uikit';
import { PAYMENT } from "config";

export const UnitPrice = () => {
    return (
        <CsPrice>
            <Text color='textSubtle' className="unit-price">{PAYMENT}</Text>
            <img src="/images/coins/vndc.png" alt="logo" style={{ width: "24px", height: "24px" }} />
        </CsPrice>
    )
};

export const Dot = () => <CsDot>:</CsDot>;

export const FontBold = ({ children }) => <CsFontBold>{children}</CsFontBold>

const CsFontBold = styled.span`
    @media screen and (max-width: 600px){
        font-weight: 600;
    }
`;

const CsDot = styled.span`
    display: none;
    @media screen and (max-width: 600px){
        display: block;
    }
`

const CsPrice = styled.div`
    img{
        display: none;
    }
    .unit-price{
        color: #02111B;
        font-weight: 600;
        margin-left: 5px;
    }
    @media screen and (max-width: 600px){
        .unit-price{
            display: none;
        }
        img{
            display: block;
            margin-left: 5px;
        }
    }
`