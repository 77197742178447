import { Flex } from "@pancakeswap/uikit";
import GetWindowSize from "@pancakeswap/uikit/src/util/getWindowSize";
import { MENU_ENTRY_HEIGHT } from "@pancakeswap/uikit/src/widgets/Menu/config";
import Container from "components/Layout/Container";
import PageFullWidth from "components/Layout/PageFullWidth";
import SubHeader from "components/Layout/SubHeader";
import SubNav from "components/Layout/SubNav";
import { WrapperExportExcel } from "components/WrapperExportExcel";
import * as React from "react";
import styled from "styled-components";
import { useFetchData } from "../hooks";
import { CustomPaginate } from "./components/CustomPaginate";
import ExportExcel from "./components/ExportExcel";
import HeaderTable from "./components/HeaderTable";
import InfoSection from "./components/InfoSection";
import Row from "./components/Row";


const pageSize = 7;
const Home = () => {
    const { dataListHome } = useFetchData();
    const windowSize = GetWindowSize();
    const [itemOffset, setItemOffset] = React.useState(0);
    const listHomeStore = dataListHome.listItemHome;

    const sortListStatus = [...listHomeStore].sort((a: any, b: any) => a.isPay - b.isPay);

    const totalSupply = listHomeStore.reduce((prev, curr): any => {
        return prev + Number(curr.totalSupply);
    }, 0);

    const totalDistributor = listHomeStore.reduce((prev, curr): any => {
        const index = prev.find((i) => i?.toLowerCase() === curr.publisher.toLowerCase());
        if (!index) {
            prev.push(curr.publisher)
        }
        return prev;
    }, []);

    const totalPrice = listHomeStore.reduce((prev, curr): any => {
        return prev + Number(curr.totalPrice);
    }, 0);

    const total = listHomeStore.length;

    return (
        <CsPageFullWidth>
            <CsContainer width="100%" height="auto">
                <SubHeader />
                <SubNav />
                <InfoSection
                    windowSize={windowSize}
                    totalPrice={totalPrice}
                    totalSupply={totalSupply}
                    totalDistributor={totalDistributor.length}
                />
                <CsFlexTable width="100%" mt="1.5rem" flexDirection="column" paddingBottom="1.5rem">
                    <HeaderTable total={total} />
                    <ContainerRow>
                        {sortListStatus.splice(itemOffset, pageSize).map((item) => (
                            <Row key={`${item.index}`} {...item} intervestTerm={item.intervestTerm === 92 ? 3 : 6} />
                        ))}
                    </ContainerRow>
                    <WrapperExportExcel>
                        <CustomPaginate total={total} pageSize={pageSize} setItemOffset={setItemOffset} />
                        <ExportExcel dataExcel={listHomeStore} />
                    </WrapperExportExcel>
                </CsFlexTable>
            </CsContainer>
        </CsPageFullWidth>
    );
};

const CsPageFullWidth = styled(PageFullWidth)``;
const CsFlexTable = styled(Flex)`
    border-radius: 12px;
    padding: 0 20px;
    background: ${({ theme }) => theme.colors.white};
    @media screen and (max-width: 768px) {
        padding: 16px 0;
        background: ${({ theme }) => theme.colors.background};
    }
`;
const CsContainer = styled(Container)`
    margin-bottom: ${MENU_ENTRY_HEIGHT}px;
    @media screen and (min-width: 1001px) and (max-width: 1280px) {
        padding-left: 6px !important;
        padding-right: 6px !important;
    }
    @media screen and (min-width: 601px) and (max-width: 1000px) {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
`;

const ContainerRow = styled(Flex)`
    width: 100%;
    flex-direction: column;
    @media screen and (max-width: 1000px) {
        gap: 22px;
    }
`;

export default Home;
