import { useTranslation } from '@pancakeswap/localization';
import { AutoRenewIcon, Box, Button, Flex, Text } from '@pancakeswap/uikit';
import BigNumber from 'bignumber.js';
import { PAYMENT, UNITDATE, formatNumberPayment } from 'config';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import moment from 'moment';
import React from 'react';
import { AssetItemsWidthBalanceHome } from 'state/manage/type';
import styled from 'styled-components';
import { convertDate } from 'utils/converDate';
import { useAdvance } from 'views/TrustPayView/Home/hook/useAdvance';
import { useApprove } from 'views/TrustPayView/Home/hook/useApprove';
import { LightTooltip } from 'components/ToolTip';
import ContainerContract from 'components/ContainerContract';
import { ExchangeToken } from 'components/ExchangeToken';
import { ImgCoinPayment } from 'components/ImgCoinPayment';
import {
    CsFlexLeft,
    CsImgCoin, CsModal,
    CsTextModalLabel,
    CsTextModalValue,
    CsTextModalValuePrice,
    CsTextStatus, CsTextVndc,
    WrapItemMobile
} from './style';

interface options {
    windowSize: number;
    title: string;
    equivalent: string;
    onDismiss?: () => void;
}
type HandleProps = AssetItemsWidthBalanceHome & options;

const styles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "6px"
};
const AdvanceMoneyModal = (props): React.ReactElement<HandleProps> => {
    const { t } = useTranslation();
    const { chainId } = useActiveWeb3React()
    const { handleApprove, pendingTx } = useApprove(props.nftAddress, chainId)
    const { handleAdvance, pendingTxAdvance } = useAdvance(props.nftAddress, props.onDismiss, props.paydayNextProfit)
    const totalBalanceUserOwn = Number(props.totalSupply) - Number(props.totalListing) - Number(props.nftBalance)
    const isNotAllowance = new BigNumber(props.allowance).isLessThanOrEqualTo(0)
    return (
        <CsModal title={props.title} onDismiss={props.onDismiss} {...props} minWidth="1200px">
            <Flex width='100%' flexDirection='column' alignItems="center">
                <TextTitle >{t('Thông tin chứng chỉ quỹ')}</TextTitle>
            </Flex>
            <Box>
                {
                    props.windowSize > 1000 ? (
                        <>
                            <WrapperItemHeader>
                                <Text width="8%" style={styles}>Tên<LightTooltip title="Tên Chứng chỉ Quỹ" /></Text>
                                <Text width="8%" style={styles}>Kỳ trả LS <LightTooltip title="Kỳ trả lợi suất" /></Text>
                                <Text width="8%" style={styles}>Ngày ĐH<LightTooltip title="Ngày đáo hạn" /></Text>
                                <Text width="8%" style={styles}>Ngày trả<LightTooltip title="Ngày trả lợi suất kế tiếp" /></Text>
                                <Text width="8%" style={styles}>Ngày còn lại</Text>
                                <Text width="8%" style={styles}>Phát hành<LightTooltip title="Tổng phát hành (CCQ)" /></Text>
                                <Text width="8%" style={styles}>Niêm yết<LightTooltip title={`Niêm yết CCQ (${PAYMENT})`} /></Text>
                                <Text width="10%" style={styles}>Tổng CCQ<LightTooltip title="Tổng Chứng chỉ Quỹ user sở hữu" /></Text>
                                <Text width="8%" style={styles}>Mệnh giá <LightTooltip title={`Mệnh giá (${PAYMENT})`} /></Text>
                                <Text width="6%" style={styles}>Lợi suất</Text>
                                <Text width="10%" style={styles}>LS cần trả<LightTooltip title={`Lợi suất cần trả (${PAYMENT})`} /></Text>
                                <Text width="9%" style={styles}>Đã tạm ứng<LightTooltip title="Đã tạm ứng" /></Text>
                            </WrapperItemHeader>
                            <Container>
                                <WrapperCard>
                                    <Text width="8%">
                                        <LightTooltip title={props.ccqName} showIcon={false}>
                                            <p
                                                style={{
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap"
                                                }}
                                            >
                                                {props.ccqName}
                                            </p>
                                        </LightTooltip>
                                    </Text>
                                    <Text width="6%">{props.intervestTerm === 92 ? 3 : 6} tháng</Text>
                                    <Text width="8%">{convertDate(Number(props.expireDate) * 1000)}</Text>
                                    <Text width="8%">{props.formatNextInterestPaymentDate}</Text>
                                    <Text width="8%">{props.dayDiff < 0 ? '0' : props.dayDiff} {UNITDATE}</Text>
                                    <Text width="8%">{formatNumberPayment(props.totalSupply)}</Text>
                                    <Text width="8%">{formatNumberPayment(props.totalListing)}</Text>
                                    <Text width="8%">{formatNumberPayment(totalBalanceUserOwn)}</Text>
                                    <Text width="8%">{formatNumberPayment(props.denominations)}</Text>
                                    <Text width="6%">{`${formatNumberPayment(props.profit)}%`}</Text>
                                    <Text width="10%"> {formatNumberPayment(props.realInterestPaid)}</Text>
                                    <Text width="8%">{formatNumberPayment(props.totalAdvanceIntervest)}</Text>
                                </WrapperCard>
                            </Container>
                            <CsFlex>
                                {props.windowSize < 768 && <Text mb="1rem" fontSize='14px' fontWeight="600">Thông tin chi tiết</Text>}
                                <CsBox>
                                    <Flex width='100%' alignItems='center' justifyContent="space-between">
                                        <CsTextModalLabel>Trạng thái</CsTextModalLabel>
                                        <CsTextStatus isDone={props.isPay}>{props.isPay ? 'Đơn vị phát hành đã thanh toán' : 'Chưa thanh toán'}</CsTextStatus>
                                    </Flex>
                                    <Flex justifyContent='space-between' width='100%' mt={['1rem', , '1.5rem']}>
                                        <Flex alignItems='center' justifyContent='space-between' width='50%'>
                                            <CsTextModalLabel>Số tiền muốn tạm ứng cho kỳ{props.presentInterVest}</CsTextModalLabel>
                                        </Flex>
                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
                                            <CsTextModalValue>
                                                <Flex>{formatNumberPayment(props.fullTermYield)}</Flex>
                                                <ImgCoinPayment />
                                                <CsTextVndc fontWeight="700">{PAYMENT}</CsTextVndc>
                                            </CsTextModalValue>
                                            <ExchangeToken price={props.fullTermYield} />
                                        </div>
                                    </Flex>
                                </CsBox>
                                <CsBox>
                                    <CsFlexLeft width='100%'>
                                        <CsTextModalLabel>Số dư của ví</CsTextModalLabel>
                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
                                            <Flex alignItems='center'>
                                                <CsTextModalValuePrice mr="10px">
                                                    {formatNumberPayment(props.balanceOf)}
                                                </CsTextModalValuePrice>
                                                <ImgCoinPayment />
                                                <CsTextVndc fontWeight="700">{PAYMENT}</CsTextVndc>
                                            </Flex>
                                            <ExchangeToken price={props.balanceOf} />
                                        </div>
                                    </CsFlexLeft>
                                </CsBox>
                            </CsFlex>
                        </>
                    ) : (
                        <>
                            <WrapItemMobile>
                                <Flex width="100%" alignItems="center" justifyContent="space-between">
                                    <Text fontSize="14px" color="textSubtle">Tên CCQ</Text>
                                    <Text fontSize="14px" color="text">{props.ccqName}</Text>
                                </Flex>
                                <Flex width="100%" alignItems="center" justifyContent="space-between">
                                    <Text fontSize="14px" color="textSubtle">Kỳ trả lợi suất</Text>
                                    <Text fontSize="14px" color="text">{props.intervestTerm === 92 ? 3 : 6} tháng</Text>
                                </Flex>
                                <Flex width="100%" alignItems="center" justifyContent="space-between">
                                    <Text fontSize="14px" color="textSubtle">Ngày trả lợi suất còn lại</Text>
                                    <Text fontSize="14px" color="text">{props.dayDiff} {UNITDATE} </Text>
                                </Flex>
                                <Flex width="100%" alignItems="center" justifyContent="space-between">
                                    <Text fontSize="14px" color="textSubtle">Tổng CCQ user sở hữu</Text>
                                    <Text fontSize="14px" color="text">{formatNumberPayment(totalBalanceUserOwn)}</Text>
                                </Flex>
                                <Flex width="100%" alignItems="center" justifyContent="space-between">
                                    <Text fontSize="14px" color="textSubtle">Lợi suất hiện tại</Text>
                                    <Text fontSize="14px" color="text">{`${formatNumberPayment(props.profit)}%`}</Text>
                                </Flex>
                                <Flex width="100%" alignItems="center" justifyContent="space-between">
                                    <Text fontSize="14px" color="textSubtle">Lợi suất thực trả kỳ tiếp theo</Text>
                                    <Text fontSize="14px" color="text">   {formatNumberPayment(props.yieldNextMonth)}</Text>
                                </Flex>
                                <Flex width="100%" alignItems="center" justifyContent="space-between">
                                    <Text fontSize="14px" color="textSubtle">Lợi nhuận kỳ trước</Text>
                                    <Text fontSize="14px" color="text">{formatNumberPayment(props.yieldLastMonth)}</Text>
                                </Flex>
                                <Flex width="100%" alignItems="center" justifyContent="space-between">
                                    <Text fontSize="14px" color="textSubtle">Đơn vị phát hành thanh toán</Text>
                                    <Text fontSize="14px" color="text" style={{ display: "flex", gap: "4px" }}>
                                        {formatNumberPayment(props.fullTermYield)}
                                        <Vndc />
                                    </Text>
                                </Flex>
                                <Flex width="100%" alignItems="center" justifyContent="space-between">
                                    <Text fontSize="14px" color="textSubtle">Tạm ứng kỳ tiếp theo</Text>
                                    <Text fontSize="14px" color="text" style={{ display: "flex", gap: "4px" }}>
                                        {formatNumberPayment(props.fullTermYield)}
                                        <Vndc />
                                    </Text>
                                </Flex>
                                <Flex width="100%" alignItems="center" justifyContent="space-between">
                                    <Text fontSize="14px" color="textSubtle">Tổng CCQ phát hành</Text>
                                    <Text fontSize="14px" color="text" style={{ display: "flex", gap: "4px" }}>
                                        {formatNumberPayment(props.totalSupply)}
                                    </Text>
                                </Flex>
                                <Flex width="100%" alignItems="center" justifyContent="space-between">
                                    <Text fontSize="14px" color="textSubtle">Tổng CCQ niêm yết</Text>
                                    <Text fontSize="14px" color="text" style={{ display: "flex", gap: "4px" }}>
                                        {formatNumberPayment(totalBalanceUserOwn)}
                                    </Text>
                                </Flex>
                                <Flex width="100%" alignItems="center" justifyContent="space-between">
                                    <Text fontSize="14px" color="textSubtle">Mệnh giá CCQ</Text>
                                    <Text fontSize="14px" color="text" style={{ display: "flex", gap: "4px" }}>
                                        {formatNumberPayment(props.denominations)}
                                    </Text>
                                </Flex>
                                <Flex width="100%" alignItems="center" justifyContent="space-between">
                                    <Text fontSize="14px" color="textSubtle">Thông báo</Text>
                                    <Text fontSize="14px" color="text" style={{ display: "flex", gap: "4px" }}>
                                        {
                                            !props.isPay && props.dayDiff <= 5
                                                ? <>
                                                    {!props.isPay && props.dayDiff < 5 &&
                                                        <>
                                                            Còn&nbsp; {props.dayDiff < 0 ? '0' : props.dayDiff} {UNITDATE}
                                                            &nbsp; đến {UNITDATE} trả lợi suất kế tiếp
                                                        </>
                                                    }
                                                    {!props.isPay && props.dayDiff === 5 &&
                                                        <>
                                                            Còn 5 {UNITDATE} đến {UNITDATE}
                                                            trả lợi suất kế tiếp
                                                            (NPH chưa thanh toán)
                                                        </>}
                                                </>
                                                : <>
                                                    <Text color="#81D401" fontWeight="600">
                                                        {
                                                            props.isPay && "Đơn vị phát hành đã thanh toán"
                                                        }
                                                    </Text>
                                                </>
                                        }
                                    </Text>
                                </Flex>
                            </WrapItemMobile>
                        </>
                    )
                }
            </Box>

            <Flex width='100%' justifyContent='center' mt={['2rem', , , '3rem']}>
                {
                    isNotAllowance ?
                        <CsButtonConfirm
                            onClick={handleApprove}
                            disabled={pendingTx}
                            endIcon={pendingTx ? <AutoRenewIcon spin color='textSubtle' /> : ""}
                        >
                            Chấp thuận
                        </CsButtonConfirm>
                        :
                        <CsButtonConfirm
                            onClick={handleAdvance}
                            disabled={pendingTxAdvance}
                            endIcon={pendingTxAdvance ? <AutoRenewIcon spin color='textSubtle' /> : ""}
                        >
                            Tạm ứng
                        </CsButtonConfirm>
                }
            </Flex>
            <ContainerContract />
        </CsModal>
    )
}

export default AdvanceMoneyModal;

const Vndc = () => <img src="/images/coins/vndc.png" alt="vndc" width="18px" height="18px" />

const CsBoxInfo = styled(Flex)`
    gap: 12px;
    width: 100%;
    height: 170px;
    overflow-y: scroll;
    padding-right: 10px;
    flex-direction: column;
      /* Scrollbar */
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.primaryBright};
    border-radius: 8px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #F4F4F5;
    border-radius: 10px;
  }

`

const CsTextNotice = styled(Text)`
    font-size: 14px;
    color: ${({ theme }) => theme.colors.red};
    @media screen and (max-width: 768px){
        font-size: 12px;
        max-width: 50%;
        text-align: right;
    }
`
const TextTitle = styled(Text)`
    font-size: 34px;
    font-weight: 700;
    letter-spacing: 0.04em;
    color: ${({ theme }) => theme.colors.text};
    @media screen and (max-width: 1000px){
        font-size: 20px;
        letter-spacing: 0;
    }
`

const CsBox = styled(Box)`
    width: 45%;
    @media screen and (max-width: 1000px){
        width: 100%;
    }
`
const CsText = styled(Text)`
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: ${({ theme }) => theme.colors.text};
  &:first-child{
    text-align: left;
  }
`

const CsButtonConfirm = styled(Button) <{ disabled: boolean }>`
    height: 60px;
    font-size: 20px;
    font-weight: 700;
    min-width: 210px;
    transition: 0.5s;
    @media screen and (max-width: 1000px){
        border-radius: 4px;
        min-width: 165px;
        font-size: 14px;
        height: 40px;
    }
`
const CsLink = styled.div`
    text-decoration: underline;
    font-size: 20px;
    font-weight: 700;
    line-height: 40px;
    color: ${({ theme }) => theme.colors.primaryBright};
    @media screen and (min-width: 601px) and (max-width: 1000px){
        font-size: 18px;
    }
    @media screen and (max-width: 600px){
            font-size: 12px;
    }
    cursor: pointer;
`

const CsFlex = styled(Flex)`
    justify-content: space-between;
    margin-top: 2rem;
    @media screen and (max-width: 1000px){
        flex-direction: column;
        margin-top: 1rem;
    }
`

const WrapperCard = styled(Flex)`
width: 100%;
padding: 0 5px;
height: 60px;
position: relative;
align-items: center;
flex-direction:row;
justify-content:space-between;
background: ${({ theme }) => theme.colors.background};
&:hover{
background: #f5f5f58a;
}
${Text}{
text-align: center;
font-size: 13px;
}
`
const Container = styled.div<{ isHaving?: boolean, background?: string }>`
width: 100%;
border-radius: 10px;
display: flex;
flex-direction: column;
justify-content: space-between;
`

const WrapperItemHeader = styled(Flex)`
    position: relative;
    flex-direction:row;
    justify-content:space-between;
    height: 100%;
    border-radius: 20px;
    margin: 24px 0 14px 0;
    ${Text}{
    text-align: center;
    font-size: 13px;
    line-height: 1.5;
    letter-spacing: 0.04em;
    color: ${({ theme }) => theme.colors.textSubtle};
    }
    @media screen and (max-width: 600px) {
        padding: 2rem 25px;
    }
`