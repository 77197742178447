import { PAYMENT, PAYMENT_TOKEN } from "config"

export const ImgCoinPayment = () => {
    return (
        <img
            className="md:w-[18px] md:h-[18px] w-4 h-4 object-contain ml-1"
            src={PAYMENT === PAYMENT_TOKEN.VND ? "/images/ccqFundgo/vndc.png" : "/images/ccqFundgo/usdt.png"}
            alt=""
        />
    );
}

