import BigNumber from "bignumber.js";
import ccqWrappedAbi from "config/abi/ccqWrappedAbi.json";
import marketplaceAbi from "config/abi/marketplaceAbi.json";
import { BASE_RATE_INTERVEST } from "config/constants";
import { accountProps } from "state/auth/type";
import multicall from "utils/multicall";
import { itemListing, ListWrapppedItems } from "./type";

export const fetchTotalItems = async (contractAddress: string, chainId: number): Promise<{ totalItems: number }> => {

    if (contractAddress?.length) {
        try {
            const calls = [
                {
                    address: contractAddress,
                    name: 'getTotalItems',
                    params: []
                }
            ]
            const results = await multicall(marketplaceAbi, calls, chainId)
            const convertResult = Number(new BigNumber(results?.toString()).toString())
            return {
                totalItems: convertResult
            }
        }
        catch (error) {
            console.log(error)
            return {
                totalItems: 0
            }
        }
    }
    return {
        totalItems: 0
    }
}

export const fetchListingItems = async (contractAddress: string, totalItems: number, account: string, chainId: number): Promise<{ listListing: itemListing[] }> => {
    if (totalItems) {
        try {
            const calls = []
            for (let index = 0; index < totalItems; index++) {
                calls.push({
                    address: contractAddress,
                    name: 'items',
                    params: [index]
                })
            }
            const resultItems = await multicall(marketplaceAbi, calls, chainId)
            const listItem = []
            for (let indexListItem = 0; indexListItem < resultItems?.length; indexListItem++) {
                listItem.push({
                    indexListItem,
                    id: Number(new BigNumber(resultItems[indexListItem]?.id?.toString()).toString()),
                    nftId: new BigNumber(resultItems[indexListItem]?.nftId?.toString()).toString(),
                    nftAddress: resultItems[indexListItem]?.nftAddress,
                    amount: new BigNumber(resultItems[indexListItem]?.amount?.toString()).toString(),
                    seller: resultItems[indexListItem]?.seller,
                    nftContract: resultItems[indexListItem]?.nftContract,
                    contractMarketPlace: contractAddress
                })
            }
            return {
                listListing: listItem
            }
        }
        catch (error) {
            return {
                listListing: []
            }
        }
    }
    return {
        listListing: []
    }
}

export const fetchListDataStatic = async (listListing: itemListing[], account: string, chainId: number, dataFilter: accountProps[]): Promise<ListWrapppedItems> => {
    const currentTime = Math.floor(Date.now() / 1000);
    try {
        if (account?.length > 0) {
            const calls = [];
            const callListvest = [];
            const callPriceAtTime = [];
            for (let index = 0; index < listListing?.length; index++) {
                calls.push({
                    address: listListing[index]?.nftAddress,
                    name: 'etfInfor',
                    params: []
                })
            }

            for (let index = 0; index < listListing?.length; index++) {
                callListvest.push({
                    address: listListing[index]?.nftAddress,
                    name: 'getMyVestList',
                    params: [listListing[index]?.contractMarketPlace, listListing[index]?.nftId]
                })
            }

            for (let index = 0; index < listListing?.length; index++) {
                callPriceAtTime.push({
                    address: listListing[index]?.nftAddress,
                    name: 'getPriceAtTime',
                    params: [currentTime]
                })
            }

            const result = await multicall(ccqWrappedAbi, calls, chainId);
            const resultVestList = await multicall(ccqWrappedAbi, callListvest, chainId);
            const resultCallPriceAtTime = await multicall(ccqWrappedAbi, callPriceAtTime, chainId);
            const listItems = []
            if (result?.length > 0) {
                for (let index = 0; index < result?.length; index++) {
                    const filterMember = dataFilter?.find((item) => item?.account?.toLowerCase() === result[index]?.publisher?.toLowerCase())
                    const resultVestingMap = resultVestList[index][0].map((vestItem) => {
                        return {
                            amount: new BigNumber(vestItem?.amount?.toString()).toString(),
                            isVested: vestItem?.isVested,
                            vestDate: Number(new BigNumber(vestItem?.vestDate?.toString()).toString()),
                            intervestPayed: new BigNumber(vestItem?.intervestPayed.toString()).toString(),
                        }
                    })
                    listItems.push({
                        ...listListing[index],
                        nftAddress: listListing[index]?.nftAddress,
                        expireDate: Number(new BigNumber(result[index]?.expireDate.toString()).toString()),
                        intervestTerm: Number(new BigNumber(result[index]?.intervestTerm.toString()).toString()) === 92 ? 3 : 6, // 92 day => 3 months, 184 day => 6 months
                        issueDate: Number(new BigNumber(result[index]?.issueDate.toString()).toString()),
                        ccqName: result[index]?.name,
                        denominations: Number(new BigNumber(result[index]?.price.toString()).dividedBy(1E18).toString()),
                        publisher: result[index]?.publisher,
                        totalSupply: Number(new BigNumber(result[index]?.totalSupply.toString()).toString()),
                        nameMember: filterMember?.nickName,
                        resultVestingMap,
                        unitPrice: new BigNumber(resultCallPriceAtTime[index][0]?.toString()).dividedBy(1e18).toString()
                    })
                }
            }

            return {
                listWrappedItem: listItems
            }
        }
        return {
            listWrappedItem: []
        }
    }
    catch (_) {
        return {
            listWrappedItem: []
        }
    }
}