import { axiosClient, tOpt } from "config/htttp";
import { AxiosError } from "axios";
import { chainKey } from "config";

export const UseUpdateAddress = async (contractName: string, contractAddress: string, blockNumber: number, totalSupply: number, address: string, chainId: number) => {
    try {
        const resultTOtp = tOpt();
        const headers = {
            "Content-Type": "application/json",
            'Accept': '*',
            'Connection': 'Keep-Alive',
            'code': `${resultTOtp}`,
        };
        await axiosClient.post("/etf-contract",
            {
                "name": contractName,
                "contractAddress": contractAddress,
                "deployBlock": blockNumber,
                "issueAmount": totalSupply,
                "receivedAddress": address,
                "isActive": true,
                chainKey: chainKey(chainId)
            },
            {
                headers
            });
    } catch (error) {
        console.log(error)
    }
};

export const GetAddress = async (chainId: number) => {
    try {
        const resultTOtp = tOpt();
        const headers = {
            "Content-Type": "application/json",
            'Accept': '*',
            'Connection': 'Keep-Alive',
            'code': `${resultTOtp}`,
        };
        const { data } = await axiosClient.get("/etf-contract/issue-suggestion", {
            headers,
            params: {
                chainKey: chainKey(chainId)
            }
        });
        return data;
    } catch (error) {
        const err = error as AxiosError
        return err.response?.data;
    }
};

export const postAddAddressAddBlacklistAPI = async (payload) => {
    try {
        const resultTOtp = tOpt();
        const headers = {
            "Content-Type": "application/json",
            'Accept': '*',
            'Connection': 'Keep-Alive',
            'code': `${resultTOtp}`,
        };
        await axiosClient.post("/etf-contract/blacklist", payload, { headers });
    } catch (error) {
        console.log(error)
    }
};

export const postRemoveAddressAddBlacklistAPI = async (payload) => {
    try {
        const resultTOtp = tOpt();
        const headers = {
            "Content-Type": "application/json",
            'Accept': '*',
            'Connection': 'Keep-Alive',
            'code': `${resultTOtp}`,
        };
        await axiosClient.delete("/etf-contract/blacklist", {
            headers,
            data: {
                ...payload
            }
        });
    } catch (error) {
        console.log(error)
    }
};

export const getAddressAddBlacklistAPI = async (address: string | string[], chainId: number) => {
    try {
        const resultTOtp = tOpt();
        const headers = {
            "Content-Type": "application/json",
            'Accept': '*',
            'Connection': 'Keep-Alive',
            'code': `${resultTOtp}`,
        };
        const { data } = await axiosClient.get(`/etf-contract/blacklist/${address}`, {
            headers,
            params: {
                chainKey: chainKey(chainId)
            }
        });
        return data.data;
    } catch (error) {
        console.log(error)
        return [];
    }
};