import { Text } from "@pancakeswap/uikit";
import { LightTooltip } from "components/ToolTip";
import { PAYMENT } from "config";
import { ContainerHeader, StyCellSTT, StyCellSmall, StyCellSttName } from "./style";

const styles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "6px"
};

const HeaderTable = ({ total }: { total: number }) => {
    return (
        <ContainerHeader>
            <StyCellSttName style={{ width: "12%" }}>
                <Text color="textSubtle" style={styles}>
                    Tên ({total})
                    <LightTooltip title="Tên Chứng chỉ Quỹ" />
                </Text>
            </StyCellSttName>
            <StyCellSmall style={{ width: "8%" }}>
                <Text color="textSubtle" style={styles}>
                    Kỳ trả LS
                    <LightTooltip title="Kỳ trả lợi suất" />
                </Text>
            </StyCellSmall>
            <StyCellSTT style={{ width: "10%" }}>
                <Text color="textSubtle" style={styles}>
                    Ngày TLSCL
                    <LightTooltip title="Ngày trả lợi suất còn lại" />
                </Text>
            </StyCellSTT>
            <StyCellSmall style={{ width: "9%" }}>
                <Text color="textSubtle" style={styles}>
                    Tổng CCQ
                    <LightTooltip title="Tổng CCQ User sở hữu" />
                </Text>
            </StyCellSmall>
            <StyCellSmall style={{ width: "4%" }}>
                <Text color="textSubtle" style={styles}>
                    LSHT
                    <LightTooltip title="Lợi suất hiện tại" />
                </Text>
            </StyCellSmall>
            <StyCellSTT style={{ width: "6%" }}>
                <Text color="textSubtle" style={styles}>
                    LSTT
                    <LightTooltip title="Lợi suất thực trả" />
                </Text>
            </StyCellSTT>
            <StyCellSTT style={{ width: "11%" }}>
                <Text color="textSubtle" style={styles}>
                    Lợi nhuận KT
                    <LightTooltip title="Lợi nhuận kỳ trước" />
                </Text>
            </StyCellSTT>
            <StyCellSTT>
                <Text color="textSubtle" style={styles}>
                    ĐVPH
                    <LightTooltip title={`Đơn vị phát hành thanh toán (${PAYMENT})`} />
                </Text>
            </StyCellSTT>
            <StyCellSTT style={{ width: "11%" }}>
                <Text color="textSubtle" style={styles}>
                    Tạm ứng KTT
                    <LightTooltip title={`Tạm ứng kỳ tiếp theo (${PAYMENT})`} />
                </Text>
            </StyCellSTT>
            <StyCellSTT>
                <Text color="textSubtle" style={styles}>
                    Tổng HT
                    <LightTooltip title="Tổng CCQ Hiện tại" />
                </Text>
            </StyCellSTT>
            <StyCellSmall style={{ width: "8%" }}>
                <Text color="textSubtle" style={styles}>
                    Tổng NY
                    <LightTooltip title="Tổng niêm yết" />
                </Text>
            </StyCellSmall>
            <StyCellSmall style={{ width: "8%" }}>
                <Text color="textSubtle" style={styles}>
                    Mệnh giá
                    <LightTooltip title={`Mệnh giá (${PAYMENT})`} />
                </Text>
            </StyCellSmall>
            <StyCellSTT style={{ width: "9%" }}>
                <Text color="textSubtle">Thông báo</Text>
            </StyCellSTT>
            <StyCellSmall />
            <StyCellSmall />
        </ContainerHeader>
    )
}

export default HeaderTable