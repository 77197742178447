import Link from 'next/link';
import styled from "styled-components";

import { Flex, NoticeIcon, NoticeIconSuccess, NoticeIconWarning, PlayIcon, Text, useModal, useTooltip } from "@pancakeswap/uikit";
import GetWindowSize from "@pancakeswap/uikit/src/util/getWindowSize";
import BigNumber from "bignumber.js";

import AdvanceMoneyModal from "components/AdvanceMoneyModal/AdvanceMoneyModal";
import { UNITDATE, formatNumberPayment } from "config";

import { CustomButton } from 'components/CustomButton';
import { LightTooltip } from 'components/ToolTip';
import { AssetItemsWidthBalanceHome } from 'state/manage/type';
import { isEventOnClick } from 'hooks/isTrustPay';

import {
    ContainerRowMobile, CsCellMobile, CsImgCoin, CsTextDone, MobileCellLarge,
    MobileCellSmall,
    StyCellSTT,
    StyCellSmall,
    StyCellSttName
} from "./style";

const Row = (props: AssetItemsWidthBalanceHome) => {
    const { nftAddress } = props;
    const link = `/trust-pay/${nftAddress}`;


    const windowSize = GetWindowSize()
    const profitConvert = Number(new BigNumber(props?.profit).toString())
    const expireDateTimeStamp = Number(props.expireDate) * 1000
    const expireDateNewDate = new Date(expireDateTimeStamp)
    const dateOfPaymentNextRate = expireDateNewDate.setDate(expireDateNewDate.getDate() + props.intervestTerm)
    const totalBalance = Number(props.totalSupply) - Number(props.totalListing) - Number(props.nftBalance)

    const [onAdvancemoneyModal] = useModal(
        <AdvanceMoneyModal
            {...props}
            dateOfPaymentNextRate={dateOfPaymentNextRate}
            windowSize={windowSize}
        />,
        true,
        true,
        `modal_advancemoneymodal_${props?.index}`
    );
    const { targetRef, tooltip, tooltipVisible } = useTooltip(
        <>
            {!props.isPay && props.dayDiff < 5 &&
                <CsTextTootip style={{ color: 'red' }}>
                    Còn&nbsp; {props.dayDiff < 0 ? '0' : props.dayDiff} {UNITDATE}
                    &nbsp; đến kỳ trả lợi suất kế tiếp
                </CsTextTootip>
            }
            {!props.isPay && props.dayDiff === 5 &&
                <CsTextTootip style={{ color: '#F9A138' }}>
                    <p>Còn 5 {UNITDATE} đến kỳ
                        trả lợi suất kế tiếp
                        (NPH chưa thanh toán)</p>
                </CsTextTootip>}
        </>,
        { placement: 'bottom-end', tooltipOffset: [20, 10] },
    )
    const color = (!props.isPay && props.dayDiff > 5) ? 'text' : (!props.isPay && props.dayDiff === 5) ? '#F9A138' : (!props.isPay && props.dayDiff < 5) ? 'red' : "text";

    const Button = () => {
        return (
            !isEventOnClick && <CustomButton
                onClick={onAdvancemoneyModal}
                isWarning={!props.isPay && props.dayDiff <= 5}
                variant="primary"
                disabled={props.isPrepay}
            >
                Tạm ứng
            </CustomButton>
        )
    };
    return (
        <Flex width="100%" height="auto">
            {windowSize > 1000 ?
                <ContainerRow>
                    <StyCellSttName style={{ width: "8%" }}>
                        <Link passHref href={link}
                        >
                            <CsPlayIcon isWarning={!props.isPay && props.dayDiff <= 5} style={{ cursor: "pointer" }} />
                        </Link>
                        <LightTooltip title={props?.ccqName} showIcon={false}>
                            <Text color={color}
                                style={{
                                    width: "70px", overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                }}>{props.ccqName}</Text>
                        </LightTooltip>
                    </StyCellSttName>
                    <StyCellSmall style={{ width: "9%" }}>
                        <Text color={color}>{props.intervestTerm === 92 ? 3 : 6} tháng</Text>
                    </StyCellSmall>
                    <StyCellSTT style={{ width: "10%" }}>
                        <Text color={color}>
                            {props.formatNextInterestPaymentDate}
                        </Text>
                    </StyCellSTT>
                    <StyCellSmall style={{ width: "9%" }}>
                        <Text color={color}>{totalBalance}</Text>
                    </StyCellSmall>
                    <StyCellSmall style={{ width: "4%" }}>
                        <Text color={color}>
                            {`${profitConvert}%`}
                        </Text>
                    </StyCellSmall>
                    <StyCellSTT style={{ width: "6%" }}>
                        <Text color={color}>
                            {formatNumberPayment(props?.yieldNextMonth)}
                        </Text>
                    </StyCellSTT>
                    <StyCellSTT style={{ width: "11%" }}>
                        <Text color={color}>
                            {formatNumberPayment(props?.yieldLastMonth)}
                        </Text>
                    </StyCellSTT>
                    <StyCellSTT>
                        <Text color={color}>
                            {formatNumberPayment(props.fullTermYield)}
                        </Text>
                    </StyCellSTT>
                    <StyCellSTT style={{ width: "11%" }}>
                        <Text color={color}>
                            {formatNumberPayment(props.fullTermYield)}
                        </Text>
                    </StyCellSTT>
                    <StyCellSTT>
                        <Text color={color}>
                            {formatNumberPayment(props.totalSupply)}
                        </Text>
                    </StyCellSTT>
                    <StyCellSmall style={{ width: "8%" }}>
                        <Text color={color}>
                            {formatNumberPayment(props.totalListing)}
                        </Text>
                    </StyCellSmall>
                    <StyCellSmall style={{ width: "8%" }}>
                        <Text color={color}>
                            {formatNumberPayment(props.denominations)}
                        </Text>
                    </StyCellSmall>
                    <StyCellSTT width="10%">
                        {
                            !props.isPay && props.dayDiff <= 5 ?
                                <>
                                    <CsText color={color} isDone={!props.isPay}>
                                        <ReferenceElement ref={targetRef}>
                                            {!props.isPay && props.dayDiff < 5 && <NoticeIcon />}
                                            {!props.isPay && props.dayDiff === 5 && <NoticeIconWarning />}
                                        </ReferenceElement>
                                        <>
                                            {tooltipVisible && tooltip}
                                        </>
                                    </CsText>
                                </>
                                :
                                <>
                                    <Text color="#81D401" fontWeight="600">
                                        {
                                            props.isPay && <LightTooltip title="Đơn vị phát hành đã thanh toán" icon={<NoticeIconSuccess />} />
                                        }
                                    </Text>
                                </>
                        }
                    </StyCellSTT>
                    <Flex width="10%">
                        <ContainerButton>
                            <Button />
                        </ContainerButton>
                    </Flex>
                </ContainerRow>
                :
                <ContainerRowMobile>
                    <Flex width="100%" height="32px" alignItems="center">
                        <MobileCellLarge>
                            <Flex width="100%" height="100%" justifyContent="flex-start" alignItems="center">
                                <Link passHref href={link}>
                                    <PlayIcon color="textSubtle" style={{ cursor: "pointer" }} />
                                </Link>
                                <Flex ml="5px" alignItems="center">
                                    <LightTooltip title={props?.ccqName} showIcon={false}>
                                        <Text color={color}
                                            style={{
                                                width: "70px", overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                            }}>{props.ccqName}</Text>
                                    </LightTooltip>
                                </Flex>
                            </Flex>
                        </MobileCellLarge>
                        <MobileCellSmall>
                            <Text textAlign="center" bold color={color}>{Number(props.dayDiff) < 0 ? '0' : props.dayDiff} {UNITDATE}</Text>
                        </MobileCellSmall>
                        <MobileCellSmall>
                            <Text textAlign="center" bold>
                                {`${formatNumberPayment(profitConvert)}%`}
                            </Text>
                        </MobileCellSmall>
                        <MobileCellSmall>
                            <Flex width="100%" justifyContent="flex-end" alignItems="center" style={{ gap: "6px" }}>
                                <CsTextPrice>
                                    {formatNumberPayment(props?.denominations)}
                                </CsTextPrice>
                                <CsImgCoin src="/images/coins/vndc.png" alt="logo" />
                            </Flex>
                        </MobileCellSmall>
                    </Flex>
                    <Flex width="100%" height="32px" alignItems="center" justifyContent="space-between">
                        <CsCellMobile width='50%' paddingLeft="1.5rem">
                            {
                                !props.isPay && props.dayDiff <= 5 ?
                                    <>
                                        <ReferenceElement ref={targetRef}>
                                            {!props.isPay && props.dayDiff < 5 && <NoticeIcon />}
                                            {!props.isPay && props.dayDiff === 5 && <NoticeIconWarning />}
                                        </ReferenceElement>
                                        <>
                                            {tooltipVisible && tooltip}
                                        </>
                                    </>
                                    :
                                    <> {props.isPay && <CsTextDone>Đã thanh toán</CsTextDone>} </>
                            }
                        </CsCellMobile>
                        <CsCellMobile width='30%'>
                            <Flex width="100%" justifyContent="flex-start" alignItems="center" style={{ gap: "6px" }}>
                                <MobileTotalSuply>
                                    {formatNumberPayment(props.totalSupply)}
                                </MobileTotalSuply>
                            </Flex>
                        </CsCellMobile>
                        <Button />
                    </Flex>
                </ContainerRowMobile>
            }
        </Flex>
    )
};

export default Row

const CsTextTootip = styled(Text)`
    font-size: 14px;
    @media screen and (max-width: 768px) {
        font-size: 12px;
    }
`
const CsPlayIcon = styled(PlayIcon) <{ isWarning: boolean }>`
    fill: ${({ theme, isWarning }) => isWarning ? theme.colors.red : theme.colors.textSubtle};
`
const ReferenceElement = styled.div`
  display: inline-block;
  cursor: pointer;
`

const ContainerRow = styled(Flex)`
    width: 100%;
    padding: 14px;
    height: auto;
    align-items: center;
    justify-content: space-between;
    min-height: 60px;
    border-radius: 8px;
    justify-content: flex-start;
    align-items: center;
    background: rgba(255, 255, 255, 0.54);
    &:hover{
        background: ${({ theme }) => theme.colors.white};
    }
    @media screen and (max-width: 900px) {
        height: auto;
        flex-direction:column;
        gap:12px;
        border: 1px solid #e8e5e5;
        background-color:transparent;
    }
    @media screen and (max-width: 1000px) {
        padding:1.5rem;
    }
`
const CsText = styled(Text) <{ isDone: boolean }>`
    color: ${({ theme, isDone }) => isDone ? "#81D401" : theme.colors.primaryBright} !important;
    font-weight:bold;
`

const CsTextPrice = styled(Text)`
    color:${({ theme }) => theme.colors.text};
    font-size:14px;
    text-align: right;
    @media screen and (min-width: 1000px) and (max-width: 1280px) {
        font-size: 13px;
    }
    @media screen and (max-width: 1000px){
            width: auto;
            font-size: 16px;
            font-weight:bold;
            color:${({ theme }) => theme.colors.text};
    }
    @media screen and (max-width: 600px){
        text-align: right;
        font-size:14px;
    }
`

const MobileTotalSuply = styled(Text)`
    color:${({ theme }) => theme.colors.text};
    font-size:14px;
    @media screen and (min-width: 1000px) and (max-width: 1280px) {
        font-size: 13px;
    }
    @media screen and (max-width: 1000px){
        text-align:initial;
        width: auto;
        font-size: 16px;
        font-weight: 500;
        color:${({ theme }) => theme.colors.text};
    }
    @media screen and (max-width: 600px){
        font-size:12px;
    }
`
const ContainerButton = styled(Flex)`
    width:100%;
    @media screen and (max-width: 1000px){
        justify-content: flex-start;
    }
`