import { MaxUint256 } from "@ethersproject/constants";
import { useTranslation } from "@pancakeswap/localization";
import { useToast } from "@pancakeswap/uikit";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { useCallWithMarketGasPrice } from "hooks/useCallWithMarketGasPrice";
import { useERC20, useWrapped } from "hooks/useContract";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "state";
import { actionDispatchMessage } from "state/homeFundgo/actions";

import { ToastDescriptionWithTx } from "components/Toast";

import { CONTENT_ERROR, chainKey } from "config";
import { renderTokenPayment } from "utils/renderMarketplaceAddress";
import { postAddAddressAddBlacklistAPI, postRemoveAddressAddBlacklistAPI } from "views/FundgoView/Release/hook/useUpdateAddress";
import { useUserRejected } from "./useUserRejected";

export const UseFundgo = (marketplaceAddressConfirm) => {
    const { handleRejected } = useUserRejected();
    const dispatch = useDispatch<AppDispatch>();
    const { chainId } = useActiveWeb3React();
    const [pendingTx, setPendingTx] = useState(false);

    const { toastSuccess, toastError } = useToast();
    const paymentToken = renderTokenPayment(chainId);

    const { callWithMarketGasPrice } = useCallWithMarketGasPrice();
    const { t } = useTranslation();
    const addressApprove = useERC20(paymentToken.address);
    const addressConfirm = useWrapped(marketplaceAddressConfirm);

    const handleApprove = useCallback(async (marketplaceAddress) => {
        setPendingTx(true);
        try {
            const tx = await callWithMarketGasPrice(addressApprove, "approve", [marketplaceAddress, MaxUint256]);
            const receipt = await tx.wait();
            if (receipt.status) {
                toastSuccess(t("Hợp đồng đã kích hoạt"), <ToastDescriptionWithTx txHash={receipt.transactionHash} />);
                setPendingTx(false);
                dispatch(actionDispatchMessage({ messageHash: receipt.transactionHash }));
            } else {
                toastError(
                    t("Đã có lỗi xảy ra"),
                    t("Xác nhận giao dịch và đảm bảo rằng bạn đủ tiền trong ví để xử lý giao dịch !")
                );
                setPendingTx(false);
            }
        } catch (e) {
            handleRejected(e)
            setPendingTx(false);
        }
    }, [callWithMarketGasPrice, addressApprove, toastSuccess, t, dispatch, toastError, handleRejected]);

    const handleConfirm = useCallback(
        async (paydayNextProfit) => {
            setPendingTx(true);
            try {
                const tx = await callWithMarketGasPrice(addressConfirm, "payIntervest", [paydayNextProfit]);
                const receipt = await tx.wait();
                if (receipt.status) {
                    toastSuccess(
                        t("Hợp đồng đã kích hoạt"),
                        <ToastDescriptionWithTx txHash={receipt.transactionHash} />
                    );
                    setPendingTx(false);
                    dispatch(actionDispatchMessage({ messageHash: receipt.transactionHash }));
                } else {
                    toastError(
                        t("Đã có lỗi xảy ra"),
                        t("Xác nhận giao dịch và đảm bảo rằng bạn đủ tiền trong ví để xử lý giao dịch !")
                    );
                    setPendingTx(false);
                }
                return {
                    status: 200
                };
            } catch (e) {
                console.error(e);
                toastError(t(CONTENT_ERROR));
                setPendingTx(false);
                return {
                    status: 401
                };
            }
        },
        [addressConfirm, callWithMarketGasPrice, dispatch, t, toastError, toastSuccess]
    );

    const handleCloseNFT = useCallback(async () => {
        setPendingTx(true);
        try {
            const tx = await callWithMarketGasPrice(addressConfirm, "pause", []);
            const receipt = await tx.wait();
            if (receipt.status) {
                toastSuccess(t("Đã khóa Contract thành công"), <ToastDescriptionWithTx txHash={receipt.transactionHash} />);
                setPendingTx(false);
                dispatch(actionDispatchMessage({ messageHash: receipt.transactionHash }));
            } else {
                toastError(
                    t("Đã có lỗi xảy ra"),
                    t("Xác nhận giao dịch và đảm bảo rằng bạn đủ tiền trong ví để xử lý giao dịch !")
                );
                setPendingTx(false);
            }
        } catch (e) {
            console.error(e);
            toastError(t(CONTENT_ERROR));
            setPendingTx(false);
        }
    }, [addressConfirm, callWithMarketGasPrice, toastSuccess, t, dispatch, toastError]);
    const handleSetConfigType = useCallback(async (payload) => {
        setPendingTx(true);
        try {
            const tx = await callWithMarketGasPrice(addressConfirm, "setConfigType", payload);
            const receipt = await tx.wait();
            if (receipt.status) {
                toastSuccess(t("Cập nhật thuộc tính thành công"), <ToastDescriptionWithTx txHash={receipt.transactionHash} />);
                setPendingTx(false);
                dispatch(actionDispatchMessage({ messageHash: receipt.transactionHash }));
            } else {
                toastError(
                    t("Đã có lỗi xảy ra"),
                    t("Xác nhận giao dịch và đảm bảo rằng bạn đủ tiền trong ví để xử lý giao dịch !")
                );
                setPendingTx(false);
            }
            return {
                status: receipt.status ? 200 : 400
            }
        } catch (e) {
            setPendingTx(false);
            return {
                status: 400
            }
        }
    }, [addressConfirm, callWithMarketGasPrice, toastSuccess, t, dispatch, toastError]);

    const handleOpenNFT = useCallback(async () => {
        setPendingTx(true);
        try {
            const tx = await callWithMarketGasPrice(addressConfirm, "unpause", []);
            const receipt = await tx.wait();
            if (receipt.status) {
                toastSuccess(t("Đã kích hoạt lại Contract"), <ToastDescriptionWithTx txHash={receipt.transactionHash} />);
                setPendingTx(false);
                dispatch(actionDispatchMessage({ messageHash: receipt.transactionHash }));
            } else {
                toastError(
                    t("Đã có lỗi xảy ra"),
                    t("Xác nhận giao dịch và đảm bảo rằng bạn đủ tiền trong ví để xử lý giao dịch !")
                );
                setPendingTx(false);
            }
        } catch (e) {
            setPendingTx(false);
            console.error(e);
            toastError(t(CONTENT_ERROR));
            setPendingTx(false);
        }
    }, [addressConfirm, callWithMarketGasPrice, toastSuccess, t, dispatch, toastError]);

    const handleAddBlacklist = useCallback(async (wallet) => {
        setPendingTx(true);
        try {
            const tx = await callWithMarketGasPrice(addressConfirm, "setBlacklist", [wallet]);
            const receipt = await tx.wait();
            if (receipt.status) {
                toastSuccess(t("Thêm vào Blacklist thành công"), <ToastDescriptionWithTx txHash={receipt.transactionHash} />);
                setPendingTx(false);
                postAddAddressAddBlacklistAPI({
                    contractAddress: marketplaceAddressConfirm,
                    blacklist: wallet,
                    chainKey: chainKey(chainId)
                });
            } else {
                toastError(
                    t("Đã có lỗi xảy ra"),
                    t("Xác nhận giao dịch và đảm bảo rằng bạn đủ tiền trong ví để xử lý giao dịch !")
                );
                setPendingTx(false);

            }
            return {
                status: receipt.status ? 200 : 400
            }
        } catch (error) {
            setPendingTx(false);
            console.log(error)
            setPendingTx(false);
            return {
                status: 400
            }
        }
    }, [addressConfirm, callWithMarketGasPrice, chainId, marketplaceAddressConfirm, t, toastError, toastSuccess]);

    const handleRemoveBlacklist = useCallback(async (wallet) => {
        setPendingTx(true);
        try {
            const tx = await callWithMarketGasPrice(addressConfirm, "removeBlacklist", [wallet]);
            const receipt = await tx.wait();
            if (receipt.status) {
                toastSuccess(t("Xóa Blacklist thành công"), <ToastDescriptionWithTx txHash={receipt.transactionHash} />);
                setPendingTx(false);
                postRemoveAddressAddBlacklistAPI({
                    contractAddress: marketplaceAddressConfirm,
                    blacklist: wallet,
                    chainKey: chainKey(chainId)
                })
            } else {
                toastError(
                    t("Đã có lỗi xảy ra"),
                    t("Xác nhận giao dịch và đảm bảo rằng bạn đủ tiền trong ví để xử lý giao dịch !")
                );
                setPendingTx(false);

            }
            return {
                status: receipt.status ? 200 : 400
            }
        } catch (error) {
            console.log(error)
            setPendingTx(false);
            return {
                status: 400
            }
        }
    }, [addressConfirm, callWithMarketGasPrice, chainId, marketplaceAddressConfirm, t, toastError, toastSuccess]);

    return { handleApprove, handleConfirm, handleCloseNFT, handleOpenNFT, handleAddBlacklist, handleRemoveBlacklist, handleSetConfigType, pendingTx };
};
