import BigNumber from 'bignumber.js';
import ccqWrappedAbi from 'config/abi/ccqWrappedAbi.json';
import multicall from 'utils/multicall';
import marketplaceAbi from 'config/abi/marketplaceAbi.json';

interface GetDataEtfType {
    isTrade: boolean;
    isInvest: boolean;
    mortgageStatus: number;
    isNftPhase2: boolean;
}

export const getDataEtfType = async (nftAddress: string, chainId: number): Promise<GetDataEtfType> => {
    try {
        const result = await multicall(ccqWrappedAbi, [{ address: nftAddress, name: 'etfType', params: [] }], chainId);
        const payload = { ...result[0] }
        return {
            isInvest: payload.isInvest,
            isTrade: payload.isTrade,
            mortgageStatus: new BigNumber(payload.mortgageStatus.toString()).toNumber(),
            isNftPhase2: true,
        };
    } catch (error) {
        return {
            isTrade: true,
            isInvest: true,
            mortgageStatus: 0,
            isNftPhase2: false,
        };
    }
};

export const getMarketplace = async (nftAddress: string, contractMarketplace: string, chainId: number) => {
    try {
        const [resultGetTotalItems] = await multicall(marketplaceAbi, [{ address: contractMarketplace, name: 'getTotalItems', params: [] }], chainId);
        const totalItems = new BigNumber(resultGetTotalItems.toString()).toNumber();

        const paramsItems = [...Array(totalItems)].map((_, index) => ({
            address: contractMarketplace, name: 'items', params: [index]
        }));
        const resultItems = await multicall(marketplaceAbi, paramsItems, chainId);
        const parseData = resultItems.map((item) => {
            return {
                amount: new BigNumber(item.amount.toString()).toNumber(),
                id: new BigNumber(item.id.toString()).toNumber(),
                nftAddress: item.nftAddress,
                nftContract: item.nftContract,
                nftId: new BigNumber(item.nftId.toString()).toNumber(),
                seller: item.seller
            }
        });
        const checkListing = parseData.find((item) => item?.nftContract?.toLowerCase() === nftAddress?.toLowerCase());
        return {
            isListing: !!checkListing
        }
    } catch (error) {
        return {
            isListing: false,
        }
    }
}