import { Flex, Text } from "@pancakeswap/uikit";
import { GetWhitelist } from "state/auth";
import styled from "styled-components";

const SubHeader: React.FC = () => {
    const data = GetWhitelist()
    return (
        <HomeHeading>
            <ContainerSubHeading>
                <CsTextHeading>
                    Quản lý Chứng chỉ Quỹ
                </CsTextHeading>
            </ContainerSubHeading>
            <ContainerIssure>
                <CsFlexIssuers>
                    <TextTitle> </TextTitle>
                    <TextTitleMobile> </TextTitleMobile>
                    <CsFlexHeading>
                        <>
                            <CsImg src={`/${data?.dataUser?.image}?version=1.2`} alt="logo" />
                            <TextIssuer bold>{data?.dataUser?.name}</TextIssuer>
                        </>
                    </CsFlexHeading>
                </CsFlexIssuers>
            </ContainerIssure>
        </HomeHeading>
    )
}

export default SubHeader

const HomeHeading = styled.div`
    margin-top: 2rem;
    height: auto;
    min-height: 170px;
    @media screen and (max-width: 600px){
        min-height: fit-content;
    }
`
const CsTextHeading = styled(Text)`
    width: 700px;
    width: 100%;
    font-size: 62px;
    font-weight: 600;
    text-align:center;
    letter-spacing: 2px;
    color: ${({ theme }) => theme.colors.primary};
    @media screen and (min-width: 600px) and (max-width: 1280px) {
        font-size: 48px;
    }
    @media screen and (max-width: 600px) {
        width: 100%;
        font-size: 24px;
    }
`
const CsFlexIssuers = styled(Flex)`
    width: 700px;
    margin-top: 54px;
    align-items: center;
    justify-content: space-between;
    @media screen and (min-width: 600px) and (max-width: 1280px) {
        width: 700px;
    }
    @media screen and (max-width: 600px){
        width: 100%;
        margin-top: 24px;
        align-items: center;
    }
`
const TextTitle = styled(Text)`
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: ${({ theme }) => theme.colors.textSubtle};
    display: block;
    @media screen and (max-width: 600px) {
        display: none;
        font-size: 16px;
    }
`
const TextTitleMobile = styled(Text)`
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    display: none;
    color: ${({ theme }) => theme.colors.textSubtle};
    @media screen and (max-width: 600px) {
        display: block;
        font-size: 12px;
    }
`
const CsImg = styled.img`
    max-width: 100px;
    objbect-fit: contain;
    @media screen and (max-width: 600px){
        height: 24px;
        width: auto;
    }
`
const TextIssuer = styled(Text)`
    color: ${({ theme }) => theme.colors.text};
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    @media screen and (max-width: 600px){
        font-size: 12px;
        line-height: 14px;
        font-weight: bold;
    }
`
const CsFlexHeading = styled(Flex)`
    align-items: center;
    justify-content: flex-end;
    gap: 14px;
    width: 50%;
    @media screen and (max-width: 600px){
        width: 80%;
        align-items: center;
        gap: 6px;
    }
`
const ContainerSubHeading = styled(Flex)`
    width: 100%;
    justify-content: center;
    align-items: center;
`
const ContainerIssure = styled(Flex)`
    width: 100%;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 600px){
        padding: 0 40px;
    }
`