import { Text } from "@pancakeswap/uikit";

import { LightTooltip } from "components/ToolTip";
import { PAYMENT } from "config";
import { ContainerHeader, StyCellSTT, StyCellSmall, StyCellSttName } from "./style";

const styles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "6px"
};

const HeaderTable = ({ total }: { total: number }) => {
    return (
        <ContainerHeader>
            <StyCellSttName>
                <Text color="textSubtle" style={styles}>Tên ({total})
                    <LightTooltip title="Tên Chứng chỉ Quỹ" />
                </Text>
            </StyCellSttName>
            <StyCellSttName>
                <Text color="textSubtle" style={styles} >
                    Đơn vị
                    <LightTooltip title="Đơn vị phân phối" />
                </Text>
            </StyCellSttName>
            <StyCellSmall>
                <Text color="textSubtle">Lợi suất</Text>
            </StyCellSmall>
            <StyCellSmall style={{ width: "8%" }}>
                <Text color="textSubtle" style={styles}>
                    Kỳ trả LS
                    <LightTooltip title="Kỳ trả lợi suất" />
                </Text>
            </StyCellSmall>
            <StyCellSTT>
                <Text color="textSubtle">
                    <Text color="textSubtle" style={styles}>
                        Ngày trả LS
                        <LightTooltip title="Ngày trả lợi suất kế tiếp" />
                    </Text>
                </Text>
            </StyCellSTT>
            <StyCellSmall style={{ width: "7%" }}>
                <Text color="textSubtle">
                    Ngày còn lại
                </Text>
            </StyCellSmall>
            <StyCellSTT style={{ width: "12%" }}>
                <Text color="textSubtle" style={styles}>
                    Tổng PH
                    <LightTooltip title="Tổng phát hành (CCQ)" />
                </Text>
            </StyCellSTT>
            <StyCellSmall style={{ width: "8%" }}>
                <Text color="textSubtle" style={styles}>
                    Mệnh giá
                    <LightTooltip title={`Mệnh giá (${PAYMENT})`} />
                </Text>
            </StyCellSmall>
            <StyCellSTT style={{ width: "10%" }}>
                <Text color="textSubtle" style={styles}>
                    Tương đương
                    <LightTooltip title={`Tương đương (${PAYMENT})`} />
                </Text>
            </StyCellSTT>
            <StyCellSTT>
                <Text color="textSubtle" style={styles}>
                    LS kỳ này
                    <LightTooltip title={`Lợi suất kỳ này (${PAYMENT})`} />
                </Text>
            </StyCellSTT>
            <StyCellSTT>
                <Text color="textSubtle" style={styles}>
                    Thanh toán
                    <LightTooltip title={`Cần thanh toán (${PAYMENT})`} />
                </Text>
            </StyCellSTT>
            <StyCellSTT>
                <Text color="textSubtle">Thông báo</Text>
            </StyCellSTT>
            <StyCellSmall />
            <StyCellSmall />
        </ContainerHeader>
    );
};

export default HeaderTable;
